import {
    CBreadcrumbRouter,
    CHeader,
    CHeaderBrand,
    CHeaderNav,
    CHeaderNavItem,
    CLink,
    CSubheader,
    CToggler,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { Dropdown, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    startUIToggleSidebar,
    startUIToggleSidebarMobile,
} from "../../redux/actions/administrador/ui/ui";
import { TheHeaderDropdown } from "./index";
import { IconApp } from "../IconApp";
import { useForm } from "../../hooks/useForm";
import useDebounce from "../../hooks/useDebounce";
import {
    searchBar,
    startShowStudentsSearchBar,
} from "../../redux/actions/students/students";
import { StudentStatusIcon } from "../StudentStatusIcon";
import { useHasPermission } from "../../hooks/useHasPermission";

const defaultValues = {
    query: "",
};

const TheHeader = () => {
    const dispatch = useDispatch();

    const { sidebarShow } = useSelector((state) => state.ui);
    const { searchBar: searchBarList } = useSelector((state) => state.students);

    const toggleSidebar = () => dispatch(startUIToggleSidebar(sidebarShow));

    const toggleSidebarMobile = () =>
        dispatch(startUIToggleSidebarMobile(sidebarShow));

    const { color_nav, logo, tiny_name } = useSelector((state) => state.config);

    const { session } = useSelector((state) => state.auth);
    const { hasPermission } = useHasPermission();
    const canShowSearchBar =
        session === "operator" &&
        hasPermission([
            "STUDENTS",
            "READ_STUDENT_DETAIL",
            "READ_LEAD_DETAIL",
            "ADMISSION_STUDENTS",
        ]);
    const {
        values: { query },
        handleInputChange,
    } = useForm(defaultValues);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const debouncedSearchValue = useDebounce(searchValue, 500);
    useEffect(() => {
        if (debouncedSearchValue !== "") {
            handleInputChange({
                target: { name: "query", value: debouncedSearchValue },
            });
        } else {
            dispatch(searchBar([]));
        }
    }, [debouncedSearchValue]);
    useEffect(() => {
        if (query !== "") {
            dispatch(startShowStudentsSearchBar({ query }));
        } else {
            dispatch(searchBar([]));
        }
    }, [query]);
    const handleSearchValue = (e) => {
        let val = e.target.value;
        setSearchValue(val);
    };
    const handleFocus = () => {
        setShowDropdown(true);
    };
    const handleBlur = () => {
        setTimeout(() => setShowDropdown(false), 100);
    };
    const preventBlur = (e) => {
        e.preventDefault();
    };

    return (
        <CHeader
            withSubheader
            colorScheme="#221e1e"
            style={{
                backgroundColor: color_nav,
                border: "0px solid" + color_nav,
            }}
        >
            <CToggler
                inHeader
                className="ml-md-3 d-lg-none"
                onClick={toggleSidebarMobile}
                name="toggle-md"
            >
                <IconApp iconClassName="fa-solid fa-bars fa-2xl text-white" />
            </CToggler>
            <CToggler
                inHeader
                className="ml-3 d-md-down-none header-toggler-color"
                onClick={toggleSidebar}
                name="toggle-lg"
            >
                <IconApp iconClassName="fa-solid fa-bars fa-2xl text-white" />
            </CToggler>
            <CHeaderBrand className="mx-auto d-lg-none" to="/">
                <Image
                    className="animate__animated animate__backInLeft"
                    src={`/storage/${logo}`}
                    rounded
                    style={{
                        height: 40,
                        objectFit: "scale-down",
                        marginRight: 0,
                    }}
                    alt={tiny_name}
                />
            </CHeaderBrand>

            <CHeaderNav className="d-md-down-none mr-auto">
                {canShowSearchBar && (
                    <div style={{ position: "relative", width: "400px" }}>
                        <div className="input-group">
                            <span className="input-group-text bg-white border-0">
                                <IconApp
                                    iconClassName={"fa fa-search pe-1"}
                                    color={"grey"}
                                />
                            </span>
                            <Form.Control
                                type="text"
                                placeholder="Buscar alumno"
                                value={searchValue}
                                onChange={handleSearchValue}
                                className="border-0"
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                        </div>
                        {showDropdown && searchBarList.length > 0 && (
                            <Dropdown.Menu
                                show
                                style={{ position: "absolute", width: "100%" }}
                                onMouseDown={preventBlur}
                            >
                                {searchBarList.map((data, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        as="a"
                                        href={`/app/students/form/${data.student_number}`}
                                        target="_self"
                                        title={`${data.username} - ${data.status} - ${data.curriculum.name}`}
                                        className="text-truncate user-select-none"
                                        style={{ maxWidth: "100%" }}
                                        onMouseDown={preventBlur}
                                    >
                                        {data.student_number}{" "}
                                        <StudentStatusIcon
                                            status={data.status}
                                            iconClassName="fa-circle"
                                            iconSizeClassName="fa-lg"
                                        />{" "}
                                        {data.full_name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        )}
                    </div>
                )}
                {!sidebarShow && (
                    <CHeaderNavItem className="px-3">
                        <Image
                            className="animate__animated animate__backInLeft"
                            src={`/storage/${logo}`}
                            rounded
                            style={{
                                height: 40,
                                objectFit: "scale-down",
                                marginRight: 0,
                            }}
                            alt={tiny_name}
                        />
                    </CHeaderNavItem>
                )}
            </CHeaderNav>

            <CHeaderNav className="px-3 text-right">
                <TheHeaderDropdown />
            </CHeaderNav>

            {/* <CSubheader className="px-3 justify-content-between">

                <CBreadcrumbRouter
                    className="border-0 c-subheader-nav m-0 px-0 px-md-3"

                />
               <div className="d-md-down-none mfe-2 c-subheader-nav">
                    <CLink className="c-subheader-nav-link" href="#">
                       settings
                    </CLink>
                    <CLink
                        className="c-subheader-nav-link"
                        aria-current="page"
                        to="/dashboard"
                    >

                        &nbsp;Dashboard
                    </CLink>
                    <CLink className="c-subheader-nav-link" href="#">

                        &nbsp;Settings
                    </CLink>
                </div>
           </CSubheader> */}
        </CHeader>
    );
};

export default TheHeader;
