import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";

const initialState = {
    students: [],
    schedule: null,
    empty: false,
    attendances: {
        pariods: [],
        school_subjects: [],
    },
    absencesReport: null,
    absences: [],
    attendace_topic: null,
    hours: [],
    pagination: { data: [], per_page: 25 },
    justifications: {
        pagination: {
            data: [],
            per_page: 10,
            total: 0,
        },
    },
};

const academicAttendance = createSlice({
    name: "academicAttendance",
    initialState,
    reducers: {
        setStudentsAttendance: (state, action) => {
            state.students = action.payload;
        },
        setSchedule: (state, action) => {
            state.schedule = action.payload;
        },
        setEmpty: (state, action) => {
            state.empty = action.payload;
        },
        setAttendances: (state, action) => {
            state.attendances = action.payload;
        },
        setAbsencesReport: (state, action) => {
            state.absencesReport = action.payload;
        },
        setAbsences: (state, action) => {
            state.absences = action.payload;
        },
        setAttendaceTopic: (state, action) => {
            state.attendace_topic = action.payload;
        },
        setHours: (state, action) => {
            state.hours = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setPaginationJustifications: (state, action) => {
            state.justifications.pagination = action.payload;
        },
        clearPaginationJustifications: (state) => {
            state.justifications = initialState.justifications;
        },
    },
});

export const {
    setStudentsAttendance,
    setSchedule,
    setEmpty,
    setAttendances,
    setAbsencesReport,
    setAbsences,
    setAttendaceTopic,
    setHours,
    setPagination,
    setPaginationJustifications,
} = academicAttendance.actions;
export default academicAttendance.reducer;

export const startGetStudentsBySchedule = (
    { teacher_id, academic_schedule_id, date, hour },
    callbackError = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const data = new URLSearchParams();
        data.append("teacher_id", teacher_id);
        data.append("academic_schedule_id", academic_schedule_id);
        data.append("date", date);
        data.append("hour", hour);

        const resp = await Call(
            `teachers/attendances/getStudentsByClass`,
            "GET",
            data
        );

        if (resp.success) {
            dispatch(setEmpty(false));
            dispatch(setStudentsAttendance(resp.success.students));
            dispatch(setSchedule(resp.success.schedule));
            dispatch(setHours(resp.success.hours));
        } else {
            dispatch(setEmpty(true));
            errorHandler(resp);
            callbackError();
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateAttendace = (
    {
        academicRecords,
        academic_schedule_id,
        date,
        hour,
        attendance_topics_id,
        setErrors,
        planning_sheet_id,
    },
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `teachers/attendances/update/attendances`,
            "POST",
            {
                academic_records: academicRecords,
                academic_schedule_id,
                date,
                hour,
                attendance_topics_id,
                planning_sheet_id,
            }
        );

        if (resp.success) {
            // Limpiar alumnos, y en el componente se vuelven a llamar inmediatamente.
            // dispatch(setStudentsAttendance([]));

            // callback();
            dispatch(setStudentsAttendance(resp.success));
            Swal.fire({
                icon: "success",
                title: "Éxito",
                text: "Asistencias guardadas.",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetAttendancesByStudent = (cycle, academic_record_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();

        params.append("cycle_id", cycle.id);
        params.append("academic_record_id", academic_record_id);
        const resp = await Call(`students/attendances`, "GET", params);

        if (resp.success) {
            dispatch(setAttendances(resp.success));
        }

        dispatch(stopUILoading());
    };
};

//* Obtener el informe de faltas por alumno en el Tab de asistencias
export const startGetAbsencesReportByStudent = ({
    period,
    status,
    from,
    to,
    subject_id,
    group_id,
    teacher_id,
    is_justify,
    cycle_id,
    academic_record_id,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.append("cycle_id", cycle_id);
        params.append("academic_record_id", academic_record_id);

        if (period) {
            params.append("period", period);
        }

        if (status) {
            params.append("status", status);
        }

        if (from && to) {
            params.append("from", from);
            params.append("to", to);
        }

        if (subject_id) {
            params.append("subject_id", subject_id);
        }

        if (group_id) {
            params.append("group_id", group_id);
        }

        if (teacher_id) {
            params.append("teacher_id", teacher_id);
        }

        if (is_justify !== null) {
            params.append("is_justify", is_justify);
        }

        const resp = await Call(`students/attendances/detail`, "GET", params);

        if (resp.success) {
            dispatch(setAbsencesReport(resp.success));
        }

        dispatch(stopUILoading());
    };
};

export const startGetAttendancesTopic = (
    subject_id,
    date,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `teachers/attendances/topics/${subject_id}/${date}`,
            "GET"
        );

        if (resp.success) {
            if (resp.success === true) {
                dispatch(setAttendaceTopic(null));
            } else {
                dispatch(setAttendaceTopic(resp.success));
            }
        } else {
            errorHandler(resp);
            callback();
        }
        dispatch(stopUILoading());
    };
};

export const startSaveAttendanceTopics = (
    data,
    setIsCaptured = () => {},
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`teachers/attendances/topics`, "POST", data);

        if (resp.success) {
            setIsCaptured(true);
            dispatch(setAttendaceTopic(resp.success));
            Swal.fire({
                title: "Éxito",
                text: "Avance programático guardado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetAttendancesTopicReport = ({
    page,
    query = "",
    number_rows,
    teacher_id,
    start_date,
    end_date,
    report,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        params.append("teacher_id", teacher_id);
        params.append("start_date", start_date);
        params.append("end_date", end_date);
        if (report) {
            params.append("report", report);
        }

        let resp;

        if (report) {
            // Descargar Excel
            resp = await CallWithFormDataFile(
                "teachers/attendances/topics/report",
                "GET",
                params
            );
            downloadBlob(resp, `DOCENTE-AVANCE-PROGRAMÁTICO-${teacher_id}`);
        } else {
            // Traer datos para la tabla
            resp = await Call(
                "teachers/attendances/topics/report",
                "GET",
                params
            );
            if (resp.success) {
                dispatch(setPagination(resp.success));
            }
        }
        dispatch(stopUILoading());
    };
};

export const startShowJustifications = ({ academic_record_id }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        if (academic_record_id) {
            params.set("academic_record_id", academic_record_id);
        }

        const resp = await Call(
            `operators/students/attendance/justify`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setPaginationJustifications(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startSaveJustification = (values, callback) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/students/attendance/justify`,
            "POST",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Justificación de falta registrada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            callback();
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteJustification = (justification_id, callback) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/students/attendance/justify/${justification_id}`,
            "DELETE"
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Justificación eliminada exitosamente.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            callback();
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};
