import { useCallback, useState } from "react";
import { setDataInPath } from "../helpers/jsonHelpers";

export const useForm = (initialState = {}) => {
    const [values, setValues] = useState({ ...initialState, errors: {} });

    const handleInputChange = useCallback(
        ({ target }, path = null) => {
            let obj = path
                ? setDataInPath(values, `${path}.${target.name}`, target.value)
                : { [target.name]: target.value };

            setValues((prevValues) => {
                // `prevValues` es el estado anterior
                const updatedValues = {
                    ...prevValues,
                    ...obj,
                };

                // Realiza cualquier lógica adicional necesaria con `prevValues`
                if (prevValues?.errors[target.name]) {
                    // Elimina el error específico del estado anterior
                    delete prevValues.errors[target.name];
                }

                return updatedValues; // Devuelve el nuevo estado actualizado
            });
        },
        [values]
    );

    const handleInputChangeUpperCase = useCallback(
        ({ target }, path = null) => {
            let obj = path
                ? setDataInPath(
                      values,
                      `${path}.${target.name}`,
                      target.value.toUpperCase()
                  )
                : { [target.name]: target.value.toUpperCase() };

            setValues((prevValues) => {
                // `prevValues` es el estado anterior
                const updatedValues = {
                    ...prevValues,
                    ...obj,
                };

                // Realiza cualquier lógica adicional necesaria con `prevValues`
                if (prevValues?.errors[target.name]) {
                    // Elimina el error específico del estado anterior
                    delete prevValues.errors[target.name];
                }

                return updatedValues; // Devuelve el nuevo estado actualizado
            });
        },
        [values]
    );

    const handleInputChangeUpperCaseNoAccents = useCallback(
        ({ target }, path = null) => {
            let processedValue = target.value
                .replace(/ñ/g, "__TEMP_N__")
                .replace(/Ñ/g, "__TEMP_N_CAP__")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/__TEMP_N__/g, "ñ")
                .replace(/__TEMP_N_CAP__/g, "Ñ")
                .toUpperCase();

            let obj = path
                ? setDataInPath(
                      values,
                      `${path}.${target.name}`,
                      processedValue
                  )
                : { [target.name]: processedValue };

            setValues((prevValues) => {
                const updatedValues = {
                    ...prevValues,
                    ...obj,
                };

                if (prevValues?.errors?.[target.name]) {
                    delete updatedValues.errors[target.name];
                }

                return updatedValues;
            });
        },
        [values]
    );

    const handleInputChangeLowerCase = useCallback(
        ({ target }, path = null) => {
            let obj = path
                ? setDataInPath(
                      values,
                      `${path}.${target.name}`,
                      target.value.toLowerCase()
                  )
                : { [target.name]: target.value.toLowerCase() };

            setValues((prevValues) => {
                // `prevValues` es el estado anterior
                const updatedValues = {
                    ...prevValues,
                    ...obj,
                };

                // Realiza cualquier lógica adicional necesaria con `prevValues`
                if (prevValues?.errors[target.name]) {
                    // Elimina el error específico del estado anterior
                    delete prevValues.errors[target.name];
                }

                return updatedValues; // Devuelve el nuevo estado actualizado
            });
        },
        [values]
    );

    const setInputValue = useCallback(
        (attribute, value, path = null) => {
            let obj = path
                ? setDataInPath(values, `${path}.${attribute}`, value)
                : { [attribute]: value };

            setValues((prevValues) => {
                // `prevValues` es el estado anterior
                const updatedValues = {
                    ...prevValues,
                    ...obj,
                };

                // Realiza cualquier lógica adicional necesaria con `prevValues`
                if (prevValues?.errors[attribute]) {
                    // Elimina el error específico del estado anterior
                    delete prevValues.errors[attribute];
                }

                return updatedValues; // Devuelve el nuevo estado actualizado
            });
        },
        [values]
    );

    const setErrors = useCallback((errors) => {
        setValues((prevValues) => ({
            ...prevValues,
            errors,
        }));
    }, []);

    const setFormValues = useCallback(
        (attributes = {}) => {
            setValues({
                ...values,
                ...attributes,
                errors: {},
            });
        },
        [values]
    );

    const reset = () => setValues({ ...initialState, errors: {} });

    return {
        values,
        handleInputChange,
        reset,
        setInputValue,
        setErrors,
        setFormValues,
        handleInputChangeUpperCase,
        handleInputChangeUpperCaseNoAccents,
        handleInputChangeLowerCase,
        errors: values.errors,
        allForm: values,
    };
};
