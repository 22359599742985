import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import { Call } from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";

const initialState = {
    data: [],
};

export const ExtraordinariesControl = createSlice({
    name: "ExtraordinariesControl",
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        clearData: (state) => {
            state.data = initialState.data;
        },
        clearState: () => {
            return initialState;
        },
    },
});

export const { setData, clearData, clearState } =
    ExtraordinariesControl.actions;

export default ExtraordinariesControl.reducer;

// Actions
export const startShowExtraordinariesControl = ({
    asList = 0,
    cycles,
    curriculum_ids,
    status,
    degree,
    letter,
    period_extra,
}) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        params.set("cycles", `${cycles?.year}-${cycles?.sub_cycle}`);

        params.set("curriculum_ids", curriculum_ids);

        if (status?.length) {
            params.set("status", status.map((item) => item.value).join(","));
        }

        params.set("degree", degree.map((item) => item.value).join(","));

        if (letter?.length) {
            params.set("letter", letter.map((item) => item.value).join(","));
        }

        if (period_extra?.length) {
            params.set(
                "period_extra",
                period_extra.map((item) => item.value).join(",")
            );
        }

        const resp = await Call(
            `operators/extraordinaries-control/show`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (resp.success?.count == 0) {
                Swal.fire({
                    title: "Sin información",
                    text: "No se encontró información de búsqueda con los filtros seleccionados.",
                    toast: true,
                    position: "bottom-end",
                    icon: "warning",
                    timer: 8000,
                });
            }
            dispatch(setData(resp.success));
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startCreateServicesByExtraordinaries = (
    values,
    callback = () => {},
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/extraordinaries-control/createServicesByExtraordinaries`,
            "PUT",
            values
        );

        if (resp.success) {
            callback(resp.success);
            Swal.fire({
                title: "Éxito",
                text: "Servicios aplicados.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};
