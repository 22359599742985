import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    active: null,
    pagination: {
        data: [],
        per_page: 25,
        total: 0,
    },
    list: [],
};

export const GraduateGroups = createSlice({
    name: "GraduateGroups",
    initialState,
    reducers: {
        setActive: (state, action) => {
            state.active = action.payload;
        },
        clearActive: (state) => {
            state.active = initialState.active;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        clearList: (state) => {
            state.list = initialState.list;
        },
        clearState: () => {
            return initialState;
        },
    },
});
export const {
    setActive,
    clearActive,
    setPagination,
    clearPagination,
    setList,
    clearList,
    clearState,
} = GraduateGroups.actions;

export default GraduateGroups.reducer;

export const startShow = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const { page, query, number_rows, degree, letter, curricula, status } =
            values;

        const params = new URLSearchParams();
        params.set("page", page);
        params.set("per_page", number_rows);
        if (query) {
            params.set("q", query);
        }
        if (degree.length > 0) {
            params.set(
                "degree",
                degree.reduce(
                    (acc, item) => acc + (acc ? "," : "") + item.value,
                    ""
                )
            );
        }
        if (letter.length > 0) {
            params.set(
                "letter",
                letter.reduce(
                    (acc, item) => acc + (acc ? "," : "") + item.value,
                    ""
                )
            );
        }
        if (curricula?.length > 0) {
            params.set(
                "curricula",
                curricula.reduce(
                    (acc, item) => acc + (acc ? "," : "") + item.value,
                    ""
                )
            );
        }
        if (status?.length > 0) {
            params.set(
                "status",
                status.reduce(
                    (acc, item) => acc + (acc ? "," : "") + item.value,
                    ""
                )
            );
        }

        const resp = await Call(
            "operators/academic_groups/graduate",
            "GET",
            params.toString()
        );

        if (resp.success) {
            const pagination = resp.success;
            dispatch(setPagination(pagination));
        }

        dispatch(stopUILoading());
    };
};

export const startShowByGroup = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const { academic_group_id } = values;

        const resp = await Call(
            "operators/academic_groups/graduate/get-by-group/" +
                academic_group_id,
            "GET"
        );

        if (resp.success) {
            const list = resp.success;
            dispatch(setList(list));
        }

        dispatch(stopUILoading());
    };
};

export const startCreate = (values, callback = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            "operators/academic_groups/graduate",
            "POST",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Solicitud creada satisfactoriamente.",
                icon: "success",
            });
            callback();
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startGraduateGroup = (values, callback = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const { academic_records_ids, status, graduate_academic_group_id } =
            values;

        const resp = await Call(
            "operators/academic_groups/graduate/" + graduate_academic_group_id,
            "PATCH",
            { status, academic_records_ids }
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Solicitud revisada satisfactoriamente.",
                icon: "success",
            });
            callback();
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};
