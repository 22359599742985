import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { Call, CallWithFormData } from "../../../helpers/fetch";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    pagination: {
        data: [],
    },
};

export const marketCashierScreen = createSlice({
    name: "marketCashier",
    initialState,
    reducers: {
        setSales: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const { setSales } = marketCashierScreen.actions;
export default marketCashierScreen.reducer;

export const startShow = (values) => {
    const { q, per_page, report, page } = values;
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", q);
        params.set("per_page", per_page);
        params.set("report", report);

        const resp = await Call(`services/market-cashier`, "GET", params);

        if (resp.success) {
            dispatch(setSales(resp.success));
        }

        dispatch(stopUILoading());
    };
};

export const startSave = (
    {
        academic_record,
        cycle,
        month_name,
        school_subject_id,
        cashier,
        totalMoney,
        shoppingCart,
        payment_type,
        card_type,
        operator_id,
        without_cycle,
    },
    student_invoice_id,
    setErrors,
    cleanAcademicRecord
) => {
    return async (dispatch) => {
        const random = Math.floor(Math.random() * 100);
        const result = await Swal.fire({
            title: "¿Está seguro de cerrar la venta?",
            icon: "warning",
            text: "¡Se guardará la venta!",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sí, cerrar venta",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            input: "text",
            html: `
            <div style="font-size: 24px">Código:</div>
            <h4 id="swal-input1" class="h4" style="font-size: 26px; font-weight: bold; color: #005AA7"> ${random}</h4>
            `,
            inputLabel: `Introduzca el código para confirmar esta operación.`,
            inputAttributes: {
                autocapitalize: "off",
                placeholder: "Introduzca el código para confirmar",
            },
            width: "500px",
        });

        if (result.value == random) {
            dispatch(startUILoading());

            const data = {
                academic_record_id: academic_record.id,
                cycle_id: cycle?.id,
                month_name,
                school_subject_id,
                point: cashier,
                total: totalMoney,
                shoppingCart: shoppingCart,
                payment_type: payment_type,
                card_type: card_type,
                user_id: operator_id,
                student_invoice_id: student_invoice_id,
                without_cycle,
            };

            const resp = await Call(`services/market-cashier`, "POST", data);

            if (resp.success) {
                cleanAcademicRecord();
                Swal.fire({
                    title: "Éxito",
                    text: "Se realizó la venta con éxito.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });

                // Abrir recibo de venta
                if (resp.success?.payment?.id) {
                    window.open(
                        `/app/payments/receipt/${resp.success?.payment?.id}`,
                        "_blank",
                        "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, toolbar=no, width=1080, height=700"
                    );
                }
            } else {
                errorHandler(resp, setErrors);
            }

            dispatch(stopUILoading());
        } else {
            Swal.fire({
                title: "error",
                text: "Introduce correctamente el número de confirmación.",
                icon: "warning",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        }
    };
};

export const startDelete = (id) => {
    return async (dispatch) => {
        deleteConfirmModal(async () => {
            dispatch(startUILoading());

            const resp = await Call(`services/market-cashier/${id}`, "DELETE");

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Se canceló la venta con éxito.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
            }

            dispatch(stopUILoading());
        });
    };
};
