import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
    downloadFileFromLink,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { divide } from "lodash";
import { startGetDownloadUri } from "./SchoolControls";

const initialState = {
    //* INICIO: STATES PARA OPERADORES
    active: null,
    activeField: null,
    activeApplication: null,
    activeMetrics: null,
    activeMetricsByTeacher: null,
    list: [],
    pagination: {
        data: [],
        per_page: 10,
        total: 0,
    },
    listApplications: [],
    paginationApplications: {
        data: [],
        per_page: 10,
        total: 0,
    },
    //* FIN: STATES PARA OPERADORES

    //* INICIO: STATES PARA ESTUDIANTES
    studentsSurveysActives: [],
    studentsSurveyAssignments: null,
    studentsUnansweredFields: [],
    surveyAssignmentStatus: "PENDIENTE",
    hasPendingSurveys: false,
    //* FIN: STATES PARA ESTUDIANTES
};

export const Surveys = createSlice({
    name: "Surveys",
    initialState,
    reducers: {
        //* INICIO: REDUCERS PARA OPERADORES
        setActive: (state, action) => {
            state.active = action.payload;
        },
        unsetActive: (state) => {
            state.active = null;
        },

        setActiveField: (state, action) => {
            state.activeField = action.payload;
        },
        unsetActiveField: (state) => {
            state.activeField = null;
        },

        setList: (state, action) => {
            state.list = action.payload;
        },
        clearList: (state) => {
            state.list = [];
        },

        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },

        setPaginationApplications: (state, action) => {
            state.paginationApplications = action.payload;
        },

        clearPaginationApplications: (state) => {
            state.paginationApplications = initialState.paginationApplications;
        },

        setListApplications: (state, action) => {
            state.listApplications = action.payload;
        },
        clearListApplications: (state) => {
            state.listApplications = initialState.listApplications;
        },

        setActiveApplication: (state, action) => {
            state.activeApplication = action.payload;
        },
        unsetActiveApplication: (state) => {
            state.activeApplication = null;
        },

        setActiveMetrics: (state, action) => {
            state.activeMetrics = action.payload;
        },
        unsetActiveMetrics: (state) => {
            state.activeMetrics = initialState.activeMetrics;
        },

        setActiveMetricsByTeacher: (state, action) => {
            state.activeMetricsByTeacher = action.payload;
        },
        unsetActiveMetricsByTeacher: (state) => {
            state.activeMetricsByTeacher = initialState.activeMetricsByTeacher;
        },
        //* FIN: REDUCERS PARA OPERADORES

        //* INICIO: REDUCERS PARA ESTUDIANTES
        setStudentsSurveysActives: (state, action) => {
            state.studentsSurveysActives = action.payload;
        },
        clearStudentsActives: (state) => {
            state.studentsSurveysActives = initialState.studentsSurveysActives;
        },

        setStudentsSurveyAssignments: (state, action) => {
            state.studentsSurveyAssignments = action.payload;
        },
        clearStudentsSurveyAssignments: (state) => {
            state.studentsSurveyAssignments =
                initialState.studentsSurveyAssignments;
            state.surveyAssignmentStatus = initialState.surveyAssignmentStatus;
        },

        setStudentsFieldAnswer: (state, action) => {
            const fields =
                state.studentsSurveyAssignments?.survey_application?.survey
                    ?.fields;
            const field = fields.find(
                (field) => field.id === action.payload.survey_field_id
            );
            if (field) {
                field.answer = action.payload;
            }
        },

        setStudentsUnansweredFields: (state, action) => {
            state.studentsUnansweredFields = action.payload;
        },
        removeStudentsUnansweredFields: (state, action) => {
            state.studentsUnansweredFields =
                state.studentsUnansweredFields.filter(
                    (id) => id != action.payload
                );
        },
        clearStudentsUnansweredFields: (state, action) => {
            state.studentsUnansweredFields =
                initialState.studentsUnansweredFields;
        },

        setStatusAssignment: (state, action) => {
            state.surveyAssignmentStatus = action.payload;
        },

        clearStatusAssignment: (state, action) => {
            state.surveyAssignmentStatus = initialState.surveyAssignmentStatus;
        },

        setHasPendingSurveys: (state, action) => {
            state.hasPendingSurveys = action.payload;
        },
        //* FIN: REDUCERS PARA ESTUDIANTES

        clearState: (state) => {
            state = initialState;
        },
    },
});

export const {
    setActive,
    unsetActive,

    setActiveField,
    unsetActiveField,

    setList,
    clearList,

    setPagination,
    clearPagination,

    setPaginationApplications,
    setListApplications,

    setActiveApplication,
    unsetActiveApplication,

    setStudentsSurveysActives,
    clearStudentsActives,

    setStudentsSurveyAssignments,
    clearStudentsSurveyAssignments,

    setActiveMetrics,
    unsetActiveMetrics,

    setActiveMetricsByTeacher,
    unsetActiveMetricsByTeacher,

    setStudentsFieldAnswer,

    setStudentsUnansweredFields,
    removeStudentsUnansweredFields,
    clearStudentsUnansweredFields,

    setStatusAssignment,
    clearStatusAssignment,

    setHasPendingSurveys,

    clearState,
} = Surveys.actions;

export default Surveys.reducer;

//* INICIO: ACTIONS PARA OPERADORES
export const startShowSurveys = ({
    page = 1,
    query = "",
    number_rows = 25,
    asList = 0,
}) => {
    return async (dispatch) => {
        if (!asList) dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        params.set("list", asList);

        const resp = await Call(
            "operators/surveys/show",
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success));
            } else {
                dispatch(setPagination(resp.success));
            }
        }

        if (!asList) dispatch(stopUILoading());
    };
};

export const startShowById = (survey_id, navigate = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/surveys/get/${survey_id}`, "GET");

        if (resp.success) {
            dispatch(setActive(resp.success));
        } else {
            navigate("/app/surveys");
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startShowApplicationById = (survey_id, navigate = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`operators/surveys/get/${survey_id}`, "GET");

        if (resp.success) {
            dispatch(setActive(resp.success));
        } else {
            navigate("/app/surveys");
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startSaveSurvey = (
    values,
    setErrors = () => {},
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call("operators/surveys/create", "POST", values);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Datos generales de la encuesta creados.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });

            callback();
            dispatch(setActive(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateSurvey = (
    survey_id,
    values,
    setErrors = () => {},
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            "operators/surveys/update/" + survey_id,
            "PUT",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Encuesta actualizada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });

            callback();
            dispatch(setActive(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startSaveSurveyField = (
    values,
    setErrors = () => {},
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            "operators/surveys/fields/create",
            "POST",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Campo de la encuesta cargado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });

            callback();
            dispatch(setActive(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateSurveyField = (
    survey_field_id,
    values,
    setErrors = () => {},
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            "operators/surveys/fields/update/" + survey_field_id,
            "PUT",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Pregunta actualizada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });

            callback();
            dispatch(setActive(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteSurveyField = (
    survey_field_id,
    callback = () => {}
) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estás seguro de eliminar?",
            icon: "warning",
            text: "¡No podrás revertir esta acción!",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sí, eliminar",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            input: "text",
            inputLabel:
                "Escribe la palabra 'eliminar' para confirmar esta operación.",
            inputAttributes: {
                autocapitalize: "off",
            },
        });

        if (result.isConfirmed && result.value === "eliminar") {
            dispatch(startUILoading());

            const resp = await Call(
                "operators/surveys/fields/delete/" + survey_field_id,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "La pregunta ha sido eliminada.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });

                callback();
                dispatch(setActive(resp.success));
            } else {
                errorHandler(resp);
            }

            dispatch(stopUILoading());
        }
    };
};

export const startCreateApplication = (
    values,
    callback = () => {},
    setErrors = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            "operators/surveys/applications/create",
            "POST",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Aplicación de encuesta creada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            callback();
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startEditApplication = (
    survey_application_id,
    values,
    callback = () => {},
    setErrors = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/surveys/applications/edit/${survey_application_id}`,
            "PUT",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Aplicación de encuesta actualizada.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            callback();
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startShowApplications = ({
    page = 1,
    query = "",
    number_rows = 25,
    asList = 0,
    users_type,
    from,
    to,
    mandatory,
}) => {
    return async (dispatch) => {
        if (!asList) dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        params.set("list", asList);

        params.set("users_type", users_type);

        params.set("from", from);
        params.set("to", to);

        params.set("mandatory", mandatory);

        const resp = await Call(
            "operators/surveys/applications/show",
            "GET",
            params.toString()
        );

        if (resp.success) {
            if (asList) {
                dispatch(setListApplications(resp.success));
            } else {
                dispatch(setPaginationApplications(resp.success));
            }
        }

        if (!asList) dispatch(stopUILoading());
    };
};

export const startGetMetricsForApplication = (
    survey_application_id,
    navigate = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/surveys/metrics/${survey_application_id}`,
            "GET"
        );

        if (resp.success) {
            dispatch(setActive(resp.success?.surveyApplication?.survey));
            dispatch(setActiveApplication(resp.success?.surveyApplication));
            dispatch(setActiveMetrics(resp.success));
        } else {
            navigate("/app/surveys");
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startGetMetricsForApplicationByTeacher = ({
    survey_application_id,
    teacher_id,
    school_subject_id,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("teacher_id", teacher_id);
        params.set("school_subject_id", school_subject_id);

        const resp = await Call(
            `operators/surveys/metrics/${survey_application_id}`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setActiveMetricsByTeacher(resp.success?.report?.teachers));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startExportSurveyApplicationAnwers = ({
    survey_application_id,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await CallWithFormDataFile(
            "operators/surveys/export/answers/" + survey_application_id,
            "GET"
        );

        downloadBlob(resp, "Respuestas de encuesta");

        dispatch(stopUILoading());
    };
};

export const startExportSurveyApplicationResults = ({
    survey_application_id,
    update = false,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        if (update) {
            params.set("update", 1);
        }

        const resp = await Call(
            "operators/surveys/export/results/" + survey_application_id,
            "GET",
            params.toString()
        );

        if (resp.success) {
            downloadFileFromLink(resp.success.uri, resp.success.fileName);
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};
//* FIN: ACTIONS PARA OPERADORES

//* INICIO: ACTIONS PARA ESTUDIANTES
export const startGetActivesSurveys = () => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        const resp = await Call(
            "students/surveys/actives",
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setStudentsSurveysActives(resp.success));
        }

        dispatch(stopUILoading());
    };
};

export const startGetSurveyAssignmentByStudent = (
    survey_assignment_id,
    navigate = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        const resp = await Call(
            `students/surveys/assigment/${survey_assignment_id}`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setStudentsSurveyAssignments(resp.success));
        } else {
            navigate("/students/surveys");
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startAnswer = (data, callback = () => {}) => {
    return async (dispatch) => {
        const resp = await Call(
            `students/surveys/assigment/answer/${data.survey_assignment_id}`,
            "POST",
            data
        );

        if (resp.success) {
            dispatch(setStudentsFieldAnswer(resp.success.answer));
            dispatch(setStatusAssignment(resp.success.assignment_status));
        } else {
            errorHandler(resp);
        }
    };
};

export const startGetHasPendingSurveys = (data, callback = () => {}) => {
    return async (dispatch) => {
        const resp = await Call("students/surveys/pending", "GET");

        dispatch(setHasPendingSurveys(!!resp.success));
    };
};
//* FIN: ACTIONS PARA ESTUDIANTES
