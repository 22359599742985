import React, { useEffect } from "react";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";
import SidebarNavAccordion from "../../../SidebarNavAccordion";
import { useDispatch, useSelector } from "react-redux";
import { startUIShowModulesCount } from "../../../../redux/actions/administrador/ui/ui";

export const Modules_School_Control = () => {
    const dispatch = useDispatch();
    const { hasPermission } = useHasPermission();
    const { color_text_menu_accordion } = useSelector((state) => state.config);

    const canShowAccordion = hasPermission([
        "ELECTRONIC_TITLES",
        "PROFESSIONAL_PRACTICES",
        "SERVICE_SOCIAL",
        "SCHOLARSHIPS",
        "TABULATORS",
        "PLANS_AND_STUDY_PROGRAMS",
        "SCHOOL_CONTROL_REPORTS",
        "DOWNLOAD_KARDEX",
        "GRADES_EDIT",
        "CYCLES",
        "TABULATOR_TEACHER",
        "TABULATORS_EDIT",
        "PLANS_AND_STUDY_PROGRAMS",
        "INFRASTRUCTURE",
        "COORDINATIONS",
        "PARTNERSHIPS",
        "AUDIT_DOCUMENTS",
        "UPDATE_MASSIVE_STUDENTS",
        "SCHOOLS_OF_ORIGINS",
        "DISTRIBUTION_LISTS",
        "ELECTRONIC_TITLES",
        "REVIEW_STATUS_CHANGES",
        "GRADES_BY_SUBJECT_REPORT",
        "GRADUATE_GROUPS",
    ]);

    useEffect(() => {
        if (canShowAccordion) {
            dispatch(startUIShowModulesCount("Modules_School_Control"));
        }
    }, [canShowAccordion]);

    // const { user } = useSelector((state) => state.auth);
    return (
        // Los permisos que agregues en este nivel van a determinar cuando se renderiza el dropdown de control escolar
        <div>
            {canShowAccordion && (
                <SidebarNavAccordion
                    title={"Control escolar"}
                    itemLevel={0}
                    color={color_text_menu_accordion}
                    iconLeftClassName="fa-solid fa-user-graduate fa-xl"
                >
                    {hasPermission(["UPDATE_MASSIVE_STUDENTS"]) && (
                        <NavLinkApp
                            name="Actualizaciones Masivas"
                            to="/app/update/massive/students"
                            icon={
                                <IconApp iconClassName="fa-solid fa-calendar-pen c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission(["SCHOLARSHIPS"]) && (
                        <NavLinkApp
                            name="Becas"
                            to="/app/scholarships"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-backpack c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("GRADES_BY_SUBJECT_REPORT") && (
                        <NavLinkApp
                            name="Calificaciones por materia"
                            to="/app/school/grade/report/by/subject"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-chart-simple c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("GRADUATE_GROUPS") && (
                        <NavLinkApp
                            name="Graduación de grupos"
                            to="/app/graduate/academicgroups"
                            icon={
                                <IconApp iconClassName="fas fa-user-graduate c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("SCHOOL_CONTROL_REPORTS") && (
                        <NavLinkApp
                            name="Actas y certificados"
                            to="/app/school/control/reports"
                            icon={
                                <IconApp iconClassName="fas fa-file-certificate c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission(["DOWNLOAD_KARDEX"]) && (
                        <NavLinkApp
                            name="Descargas de kardex"
                            to="/app/download/kardex"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-file-arrow-down c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("GRADES_EDIT") && (
                        <NavLinkApp
                            name="Editar calificaciones"
                            to="/app/school/grades/edit"
                            icon={
                                <IconApp iconClassName="fas fa-file-chart-column c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("DISTRIBUTION_LISTS") && (
                        <div>
                            <NavLinkApp
                                name="Listas de distribución"
                                to="/app/distribution_lists"
                                icon={
                                    <IconApp iconClassName="fas fa-users-between-lines c-sidebar-nav-icon"></IconApp>
                                }
                            />
                        </div>
                    )}

                    {hasPermission("PROFESSIONAL_PRACTICES") && (
                        <NavLinkApp
                            name="Prácticas profesionales"
                            to="/app/professional_practices/list"
                            icon={
                                <IconApp iconClassName="fas fa-hands-helping c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("AUDIT_DOCUMENTS") && (
                        <div>
                            <NavLinkApp
                                name="Revisión de documentos"
                                to="/app/students/documents/validation"
                                icon={
                                    <IconApp iconClassName="fas fa-solid fa-file-magnifying-glass c-sidebar-nav-icon"></IconApp>
                                }
                            />
                        </div>
                    )}

                    {hasPermission("REVIEW_STATUS_CHANGES") && (
                        <div>
                            <NavLinkApp
                                name="Revisión de estatus"
                                to="/app/students/review-status-changes"
                                icon={
                                    <IconApp iconClassName="fas fa-solid fa-user-tag c-sidebar-nav-icon"></IconApp>
                                }
                            />
                        </div>
                    )}

                    {hasPermission("SERVICE_SOCIAL") && (
                        <NavLinkApp
                            name="Servicio Social"
                            to="/app/service_social/list"
                            icon={
                                <IconApp iconClassName="fas fa-praying-hands c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission("ELECTRONIC_TITLES") && (
                        <NavLinkApp
                            name="Títulos electrónicos"
                            to="/app/students/electronic-titles"
                            icon={
                                <IconApp iconClassName="fas fa-check-double c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}

                    {hasPermission([
                        "CYCLES",
                        "TABULATOR_TEACHER",
                        "TABULATORS_EDIT",
                        "PLANS_AND_STUDY_PROGRAMS",
                        "INFRASTRUCTURE",
                        "COORDINATIONS",
                        "PARTNERSHIPS",
                        "SCHOOLS_OF_ORIGINS",
                    ]) && (
                        <SidebarNavAccordion
                            title={"Catálogos"}
                            itemLevel={1}
                            color={color_text_menu_accordion}
                            iconLeftClassName={"fa-solid fa-server fa-xl me-2"}
                        >
                            {hasPermission("CYCLES") && (
                                <NavLinkApp
                                    name="Ciclos escolares"
                                    to="/app/teacher/cycles/list"
                                    icon={
                                        <IconApp iconClassName="fas fa-graduation-cap c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}

                            {hasPermission("PLANS_AND_STUDY_PROGRAMS") && (
                                <>
                                    <NavLinkApp
                                        name="Oferta educativa"
                                        to="/app/curricula/list"
                                        icon={
                                            <IconApp iconClassName="fas fa-layer-group c-sidebar-nav-icon"></IconApp>
                                        }
                                    />

                                    <NavLinkApp
                                        name="Materias"
                                        to="/app/school_subjects/list"
                                        icon={
                                            <IconApp iconClassName="fas fa-book-reader c-sidebar-nav-icon"></IconApp>
                                        }
                                    />
                                </>
                            )}

                            {/* SE MOVIÓ A REPORTES (Modules_Reports.jsx) */}
                            {hasPermission("INFRASTRUCTURE") && (
                                <NavLinkApp
                                    name="Infraestructura"
                                    to="/app/operators/infrastructure/building"
                                    icon={
                                        <IconApp iconClassName="fas fa-building c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}

                            {hasPermission("COORDINATIONS") && (
                                <NavLinkApp
                                    name="Coordinaciones"
                                    to="/app/operators/coordinations"
                                    icon={
                                        <IconApp iconClassName="fa-solid fa-calendar-lines-pen c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}

                            {hasPermission("SCHOOLS_OF_ORIGINS") && (
                                <NavLinkApp
                                    name="Escuelas procedencia"
                                    to="/app/school_of_origins"
                                    icon={
                                        <IconApp iconClassName="fa-solid fa-school-circle-check c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}
                            {hasPermission("PARTNERSHIPS") && (
                                <NavLinkApp
                                    name="Convenios académicos"
                                    to="/app/partnerships"
                                    icon={
                                        <IconApp iconClassName="fas fa-handshake-simple c-sidebar-nav-icon"></IconApp>
                                    }
                                />
                            )}
                        </SidebarNavAccordion>
                    )}
                </SidebarNavAccordion>
            )}
        </div>
    );
};
